import React, { useState } from "react";
import styled from "styled-components";
import BannerRedesign from "../components/BannerRedesign";
import Layout from "../components/layout";
import SectionEzDivisorConsultation from "../components/ReUsables/SectionEzDivisorConsultation";
import SectionEzDivisorNewsLetter from "../components/ReUsables/SectionEzDivisorNewsLetter";
import SectionLatestBlogPosts from "../components/ReUsables/SectionLatestBlogPosts";
import SectionWithIcons from "../components/ReUsables/SectionWithIcons";
import SectionWithImage from "../components/ReUsables/SectionWithImage";
import ServicesWeOffer from "../components/ReUsables/ServicesWeOffer";
import Testimonials from "../components/ReUsables/Testimonials";
import {
  GlobalOptions,
  LastThreePostsType,
  MenuItems,
  SectionWithImageType,
  ServicesWeOfferType,
  TestimonialsType,
  WhyChooseUsType,
} from "../shared/interfaces/graphql.interface";
import { EzDivisor, RespContainer } from "../styles/common";
import { Devices, Colors } from "../styles/variables";

interface ServicesDataProps {
  pageContext: { data: ServicesContext };
}

interface ServicesContext {
  testimonials: TestimonialsType;
  globalOptions: GlobalOptions;
  lastThreePost: LastThreePostsType;
  footerMenuItems: MenuItems;
  headerMenuItems: MenuItems;
  title: string;
  content: string;
  acf: {
    banner_title: string;
    banner_subtitle: string;
    banner_description: string;
    banner_image: {
      localFile: any;
      alt_text: string;
    };
    designed_to_convert: SectionWithImageType;
    // developed_to_perfection: [
    //   {
    //     icon: {
    //       url: {
    //         source_url: string;
    //       };
    //     };
    //     title: string;
    //     description: string;
    //   }
    // ];
    services_we_offer: ServicesWeOfferType;
    why_choose_us: WhyChooseUsType;
  };
}

const services: React.FC<ServicesDataProps> = ({ pageContext }) => {
  let pageContextData: ServicesContext = pageContext.data;
  const { acf } = pageContextData;
  return (
    <>
      <BannerRedesign
        title={acf.banner_title}
        title_2={pageContextData.acf.banner_title_2}
        subtitle={acf.banner_subtitle}
        banner={acf.banner_image}
      />

      <GrayBgWrapper>
        <ServicesWeOffer services_we_offer={acf.services_we_offer} />
      </GrayBgWrapper>

      <SectionEzDivisorConsultation
        section={pageContextData.globalOptions.ez_divisor_consultation}
        phone={pageContextData.globalOptions.company_information.phone}
      />
{/* 
      <GrayBgWrapper>
        <WhyChooseUs>
          <WhyChooseUsTitle>Why Choose Us.</WhyChooseUsTitle>
          <WhyChooseUsTitleDivisor alwaysBlue />
          <ItemsBox>
            {acf.why_choose_us.map((service, idx) => (
              <WhyChooseUsWrapper key={idx}>
                <WhyChooseUsIconWrapper>
                  <WhyChooseUsIcon
                    src={service.item.icon.source_url}
                    alt={service.item.icon.alt_text}
                  />
                </WhyChooseUsIconWrapper>
                <WhyChooseUsItemTitle>
                  {service.item.title}
                </WhyChooseUsItemTitle>
                <WhyChooseUsSubtitle>
                  {service.item.subtitle}
                </WhyChooseUsSubtitle>
              </WhyChooseUsWrapper>
            ))}
          </ItemsBox>
        </WhyChooseUs>
      </GrayBgWrapper> */}

      <GrayBgWrapper>
        <Testimonials testimonials={pageContextData.testimonials} />
      </GrayBgWrapper>

      {/* <SectionEzDivisorNewsLetter
        section={pageContextData.globalOptions.ez_divisor_newsletter}
      /> */}

      <SectionLatestBlogPosts lastThreePost={pageContextData.lastThreePost} />
    </>
  );
};

export default services;

const GrayBgWrapper = styled("div")`
  background-color: ${Colors.gray7};

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
  }
`;

const ItemsBox = styled("div")`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  /* >= 768px; */
  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    flex-direction: row;
  }
`;

const WhyChooseUs = styled(RespContainer)`
  background-color: ${Colors.gray7};
  padding-bottom: 52px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
  }
`;

const WhyChooseUsTitle = styled("h2")`
  width: 100%;
  font-size: 30px;
  font-weight: 600;
  color: ${Colors.dodgeBlue2};
  margin-top: 38px;
  margin-bottom: 14px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
  }
`;

const WhyChooseUsTitleDivisor = styled(EzDivisor)`
  width: 100%;
  margin-bottom: 40px;
`;

const WhyChooseUsWrapper = styled("div")`
  padding: 20px;
  margin-bottom: 14px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.white};
  border-radius: 8px;
  max-width: 576px;

  /* >= 768px; */
  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    flex: 0 0 47%;
    max-width: unset;

    padding-left: 15px;
    padding-right: 15px;
    margin-right: 15px;
    &:nth-child(2n) {
      margin-right: unset;
    }
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    flex: 0 0 31%;

    padding-left: 15px;
    padding-right: 15px;
    margin-right: 15px;
    &:nth-child(2n) {
      margin-right: 15px;
    }
    &:nth-child(3n) {
      margin-right: unset;
    }
  }
`;

const WhyChooseUsIconWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  margin-bottom: 22px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
  }
`;

const WhyChooseUsIcon = styled("img")`
  height: 100%;
  min-height: 100%;
`;

const WhyChooseUsItemTitle = styled("h3")`
  font-size: 20px;
  color: ${Colors.dodgeBlue2};
  margin-bottom: 21px;
  font-weight: 600;
`;

const WhyChooseUsSubtitle = styled("h4")`
  font-size: 18px;
  color: ${Colors.solidGray};
  line-height: 160%;
  margin-bottom: 0;
  width: 100%;
`;
