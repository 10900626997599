import { Link } from "gatsby";
import styled from "styled-components";
import {
  AppButton,
  AppButtonAnchor,
  AppButtonLink,
  DotsBackground,
  RespContainer,
} from "../common";
import { Colors, Devices } from "../variables";

export const Container = styled("div")`
  display: flex;
  height: 530px;
  position: relative;
  color: ${Colors.white};
  flex-direction: column;
  justify-content: center;
  padding: 3.125rem 1.25rem 4rem;
  min-width: 320px;

  /* >= 1200; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    height: 340px;
  }
`;

export const Dots = styled(DotsBackground)`
  position: absolute;
  top: -122px;
  right: 80px;
  z-index: -1;
  opacity: 0.5;

  display: none;
  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    display: block;
  }
  /* >= 1440px;
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    bottom: -60px;
    left: -60px;
  } */
`;

export const CoverOverlayColor = styled("div")`
  display: initial;
  position: absolute;
  top: 0;
  left: 0;

  background: ${Colors.dodgeBlue};
  height: 100%;
  width: 100%;
  z-index: -1;
`;

export const CoverImage = styled("img")`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.15;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
`;

export const Body = styled("div")`
  display: flex;
  flex-direction: column;
  /* margin-top: 0.875rem; */
  width: 100%;

  align-items: center;
  text-align: center;

  /* >= 1200; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    flex-direction: row;

    text-align: left;
  }
`;

export const Content = styled(RespContainer)`
  align-items: flex-start;
  padding: 0;

  min-width: 285px;

  /* <= 1200; */
  @media all and (max-width: calc(${Devices.tabletLaptopBreakpoint} - 1px)) {
    max-width: 540px;
  }
`;

export const TextWrapper = styled("div")`
  width: 100%;

  /* >= 1200; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    width: 45%;
  }
`;
export const ButtonsWrapper = styled("div")`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;

  /* >= 1200; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    width: 55%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const Text = styled("h2")`
  font-weight: 600;
  font-size: 2.5rem;

  /* >= 1200; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    font-size: 2.75rem;
    line-height: 3rem;
    padding-right: 4.5rem;

    max-width: 33rem;
  }

  /* >= 1440px; */
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    font-size: 3.25rem;
    line-height: 3.5rem;
    padding-right: 4.5rem;
  }

  /* >= 1800px; */
  @media all and (min-width: ${Devices.laptopBreakpoint}) {
    font-size: 3.75rem;
    line-height: 4rem;
    padding-right: 5.5rem;
    max-width: 40rem;
  }
`;

export const ConsultationButton = styled(AppButtonLink)`
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  width: 100%;
`;

export const Phone = styled("span")`
  color: ${Colors.solidGray};
`;

export const CallButton = styled(AppButtonAnchor)`
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  width: 100%;

  margin-top: 2.375rem;
  margin-bottom: 1.5rem;

  /* >= 1200; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-top: unset;
    margin-bottom: unset;
    margin-right: 2.5rem;
  }

  &:hover,
  &:focus {
    > span {
      color: ${Colors.white};
    }
  }
`;
