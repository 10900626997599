import { Link } from "gatsby";
import { getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { LastThreePostsType } from "../../shared/interfaces/graphql.interface";
import { FontelloIcon } from "../../styles/CommonComponents";
import * as LatestBlogPosts from "../../styles/Reusables/StylingSectionLatestBlogPosts";
import EzReadMore from "./EzReadMore";

interface SectionLatestBlogPostsProps {
  lastThreePost: LastThreePostsType;
}

const SectionLatestBlogPosts: React.FC<SectionLatestBlogPostsProps> = ({
  lastThreePost,
}) => {
  return (
    <LatestBlogPosts.Container>
      <LatestBlogPosts.Wrapper>
        <LatestBlogPosts.Header>
          <LatestBlogPosts.Title>Latest Blog Posts</LatestBlogPosts.Title>
          <LatestBlogPosts.Divisor></LatestBlogPosts.Divisor>
          <LatestBlogPosts.ViewAllPosts>
            <EzReadMore to={"/blog"} customText={"View All"} />
          </LatestBlogPosts.ViewAllPosts>
        </LatestBlogPosts.Header>

        <LatestBlogPosts.BlogPosts>
          {lastThreePost.map((post, idx) => (
            <LatestBlogPosts.Post key={`post${idx}`}>
              <Link to={post.node.path}>
                <LatestBlogPosts.PostImage
                  image={
                    getImage(
                      post.node.featured_media?.localFile
                    ) as IGatsbyImageData
                  }
                  alt={post.node.featured_media?.alt_text}
                />
              </Link>
              <Link to={post.node.path}>
                <LatestBlogPosts.PostTitle>
                  {post.node.title}
                </LatestBlogPosts.PostTitle>
              </Link>
              <LatestBlogPosts.PostDescription
                dangerouslySetInnerHTML={{
                  __html: post.node.excerpt.slice(0, 90),
                }}
              />
              <LatestBlogPosts.PostReadMore>
                <EzReadMore to={post.node.path} />
              </LatestBlogPosts.PostReadMore>
            </LatestBlogPosts.Post>
          ))}

          <LatestBlogPosts.ViewAllPostsMobile>
            VIEW ALL BLOG POSTS <FontelloIcon icon={"ez-right"} />
          </LatestBlogPosts.ViewAllPostsMobile>
        </LatestBlogPosts.BlogPosts>
      </LatestBlogPosts.Wrapper>
    </LatestBlogPosts.Container>
  );
};

export default SectionLatestBlogPosts;
