import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { AppButton, EzDivisor, RespContainer } from "../common";
import { Colors, Devices } from "../variables";

export const Container = styled(RespContainer)`
  /* max-width: 1140px; */
`;

export const Wrapper = styled("div")`
  width: 100%;
  margin-top: 3.5rem;

  /* >=1200px */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    /* margin-top: 10rem; */
    margin-top: 100px;
  }
`;

export const Header = styled("div")`
  position: relative;
`;

export const Title = styled("h2")`
  padding: 0;
  font-weight: 600;
  margin: 0 0 1.375rem 0;
  color: ${Colors.ezBlack};
  font-size: 2rem;
  /* text-align: center; */

  /* >=1200px */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    /* text-align: unset; */
    font-size: 3.75rem;
    line-height: 4rem;
    /* margin-bottom: 3.25rem; */
    margin-bottom: 33px;
  }
`;

export const Divisor = styled(EzDivisor)`
  height: 3px;

  &::after {
    background-color: ${Colors.sharpBlue};
  }
`;

export const ViewAllPostsMobile = styled(AppButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 340px;

  > span {
    margin-left: 10px;
    margin-top: 2px;
    font-size: 12px;
  }

  /* >=1200px */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    display: none;
  }
`;

export const ViewAllPosts = styled("div")`
  display: none;

  /* >=1200px */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    display: block;
    position: absolute;
    right: 0;
    bottom: 3px;

    a {
      font-size: 18px;
      font-weight: 600;
      text-decoration: none;
    }
  }
`;

export const BlogPosts = styled("div")`
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  align-items: center;

  margin-top: 2.5rem;
  margin-bottom: 1.625rem;

  /* >=1200px */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    /* margin-top: 5.25rem;
    margin-bottom: 12.75rem; */
    flex-direction: row;
    align-items: flex-start;

    margin-top: 42px;
    margin-bottom: 80px;

    > div:not(:last-child) {
      margin-right: 2rem;
    }
  }
`;

export const Post = styled("div")`
  /* flex: 0 0 33%; */
  width: 100%;
  max-width: 450px;
  margin-bottom: 2rem;

  /* >=1200px */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    width: 500px;
    max-width: unset;
    margin-bottom: unset;
  }
`;

export const PostTitle = styled("h3")`
  color: ${Colors.ezBlack};
  margin: 1.625rem 0 0.9375rem;
  font-size: 1.375rem;
  font-weight: 600;
  padding: 0;

  /* >=1200px */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin: 32px 0 22px;
  }
`;

export const PostImage = styled(GatsbyImage)`
  width: 100%;
  object-fit: cover;

  height: 190px;

  /* >=1200px */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    /* height: 250px; */
    height: 410px;
  }
`;

export const PostDescription = styled("p")`
  font-weight: 400;
  font-size: 0.9375rem;
  line-height: 1.75rem;
  color: ${Colors.solidGray};
  margin: 0 0 0.875rem 0;
  padding: 0 1.5rem 0 0;

  /* >=1200px */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-bottom: 30px;
    line-height: 160%;
  }
`;

export const PostReadMore = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.ezBlack};
  font-weight: 600;
`;
