import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Colors, Devices } from "../styles/variables";
import { EzDivisor, RespContainer } from "../styles/common";
import { useWindowSize } from "./CustomHooks/useWindowSize";
import { FontelloIcon } from "../styles/CommonComponents";
import { Link } from "gatsby";
import { AppButtonLink } from "../styles/common";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";

interface BannerProps {
  title: string;
  title_2: string;
  subtitle: string;
  banner: {
    localFile: any;
    alt_text: string;
  };
  hideButton?: boolean;
}

const Banner: React.FC<BannerProps> = ({
  title,
  title_2,
  subtitle,
  banner,
  hideButton,
}) => {
  const { width }: { width?: string } = useWindowSize();

  return (
    <BannerWrapper>
      <TextsContent>
        <Title>
          {title}
          <TitlePattern>{title_2}</TitlePattern>
        </Title>
        <EzDivisorBanner alwaysBlue />
        <Subtitle>{subtitle}</Subtitle>
      </TextsContent>
      <BannerImageContent>
        <BannerImage
          image={getImage(banner?.localFile) as IGatsbyImageData}
          alt={banner?.alt_text}
        />
        <ButtonWrapper hideButton={hideButton}>
          <ConsultationButton to={`/contact`}>
            GET A FREE CONSULTATION
          </ConsultationButton>
        </ButtonWrapper>
      </BannerImageContent>
    </BannerWrapper>
  );
};

export default Banner;

const BannerWrapper = styled(RespContainer)`
  display: flex;

  flex-direction: column;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    flex-direction: row;

    /* max-width: 1690px; */
    /* max-width: 1510px; */
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  /* @media all and (min-width: 1550px) {
    max-width: 1510px;
  } */
`;

const TextsContent = styled("div")`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.75rem;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    display: flex;
    flex-direction: column;
    width: 60% !important;

    margin-bottom: 0;
  }
`;

const BannerImageContent = styled("div")`
  align-self: center;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    width: 40%;
  }
`;

const Subtitle = styled("p")`
  font-weight: 300;
  font-size: 1rem;
  color: ${Colors.solidGray};
  margin-top: 1.5rem;
  line-height: 1.4;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    max-width: 95%;
  }

  @media all and (min-width: ${Devices.tabletBreakpoint}) {
    font-size: 1.125rem;
  }
`;

const EzDivisorBanner = styled(EzDivisor)`
  margin-top: 0.875rem;
`;

const TitlePattern = styled("span")`
  display: block;
  color: ${Colors.ezBlack};
`;

const Title = styled("h1")`
  color: ${Colors.sharpBlue};
  margin-top: 1rem;
  font-size: 2.5rem;
  font-weight: 500;
  /* max-width: 27rem; */

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-bottom: 3rem;
    font-size: 3.5rem;
    margin: 0;
    /* max-width: 36rem; */
  }

  /* >= 1440px; */
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    /* margin-bottom: 5.25rem; */
    margin-bottom: 3rem;
    font-size: 4.5rem;
    margin-top: 43px;
    margin-bottom: 23px;
  }

  /* >= 1800px; */
  /* @media all and (min-width: ${Devices.laptopBreakpoint}) {
    margin-bottom: 6.375rem;
    font-size: 5.4rem;
    padding: 0 15px;
  } */
`;

const BannerImage = styled(GatsbyImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50px 50px 0 0;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    border-radius: 100px 0 0 100px;
  }

  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    max-width: 652px;
    /* height: 550px; */
    /* display: none; */
  }
`;

const ConsultationButton = styled(AppButtonLink)`
  font-size: 1rem;
  text-align: center;
  text-decoration: none;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    display: none;
  }
`;

const ButtonWrapper = styled("div")<{ hideButton?: boolean }>`
  margin-top: 45px;
  display: ${(props) => (props.hideButton ? "none" : "flex")};
`;
