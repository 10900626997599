import React from "react";
import * as Consultation from "../../styles/Reusables/StylingConsultation";

interface SectionEzDivisorConsultationProps {
  section: {
    cover_image: {
      source_url: string;
      alt_text: string;
    };
    main_text: string;
    call_button_text: string;
    consultation_button_text: string;
  };
  phone: string;
}

const SectionEzDivisorConsultation: React.FC<SectionEzDivisorConsultationProps> = ({
  section,
  phone,
}) => {
  return (
    <Consultation.Container>
      <Consultation.Dots />
      <Consultation.CoverOverlayColor />
      <Consultation.CoverImage
        src={section.cover_image.source_url}
        alt={section.cover_image?.alt_text}
      />

      <Consultation.Content>
        <Consultation.Body>
          <Consultation.TextWrapper>
            <Consultation.Text>{section.main_text}</Consultation.Text>
          </Consultation.TextWrapper>

          <Consultation.ButtonsWrapper>
            <Consultation.CallButton href={`tel:${phone}`} reversedTheme>
              {section.call_button_text}
              <Consultation.Phone>{phone}</Consultation.Phone>
            </Consultation.CallButton>

            <Consultation.ConsultationButton to={`/contact`}>
              {section.consultation_button_text}
            </Consultation.ConsultationButton>
          </Consultation.ButtonsWrapper>
        </Consultation.Body>
      </Consultation.Content>
    </Consultation.Container>
  );
};

export default SectionEzDivisorConsultation;
